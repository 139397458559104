/*global jQuery */
/*global window */
/*global document */
(function ($, window, document) {
    'use strict';
    // && !(window.eurovia instanceof HTMLElement)
    if (window.eurovia instanceof HTMLElement) {
        window.eurovia = null;
    }
    window.eurovia = (window.eurovia) || {
        init: function () {
            this.menuTop();
            this.menu();
            this.searchTop();
            this.searchBody();
            this.sliderMain();
            this.slider();
            this.sliderSolutions();
            this.animateElements();
            this.carouselNews();
            this.timeline();
            this.mapWorld();
            //this.buttonContact();
            //this.filterArchives();
            //this.articlesInFlyBox();
            this.selectFilter();
            /* eajd*/
            this.plugFlyBoxOpen();
            this.plugFlyBox2Open();
            this.plugArticlesFilter();
            this.plugBangLinks();
            this.plugAjaxMoreLoad();
            this.plugContactForm();
            this.plugCheckBoxAllnone();
            this.plugInfosNewsAndPublisFilters();
            this.plugInfosMediasFilter();
            this.fixSelectFilterZIndex();
            this.immediateFlyBox();
            this.carouselMedias();
            this.plugZapBoxes();
            this.hashBang();
            this.plugBanner();
        },

        plugBanner: function () {
            if (!$('#eurovia-banner').length) {
                return; // false;
            }

            /* jquery is already loaded, no need to define paths, just pass reference to the existing one */
            define('jquery', function () {
                return $;
            });
            /* load concatenated banner file, then find 'app' module inside and initialize it */
            require(['/js/w314/eurovia-banner.min.js'], function () {
                require(['app'], function (app) {
                    app.initialize(document.documentElement.lang);
                });
            });
        },

        menuTop: function () {
            var $menu_top = $('.menu-top>ul>li');

            $menu_top.each(function () {
                var $this = $(this);

                if ($this.find('ul').length) {
                    $this.addClass('sub-menu');

                    // use >a to do it ONLY of IMMEDIATE children
                    $this.find('>a').on('click', function (event) {
                        // do NOT do it if normal menu
                        var $page_header = $('.title-bar');
                        if (!$page_header.hasClass('active')) return;

                        event.preventDefault();

                        var li = $(this).parent();
                        if (li.hasClass('selected-menu')) {
                            li.removeClass('selected-menu');
                        } else {
                            $('.menu-top li.selected-menu').removeClass('selected-menu');
                            li.addClass('selected-menu');
                        }
                    });
                }
            });
        },

        menu: function () {
            var $page_header = $('.title-bar');

            $('.js-title-bar-mobile-developed-btn').on('mousedown', function (event) {
                event.preventDefault();
            });

            $('.js-title-bar-mobile-developed-btn').on('click', function () {
                if ($page_header.hasClass('active')) {
                    $page_header.removeClass('active');
                } else {
                    $page_header.addClass('active');
                }
            });

            $(window).resize(function () {
                if ($(window).width() > 979 && $page_header.hasClass('active')) {
                    $page_header.removeClass('active');
                }
            });
        },

        searchTop: function () {
            var $search = $('.title-bar-search');

            // but why isn't it a form?!
            $search.find('input').on('keydown', function (event) {
                if (event.which == 27) { // esc
                    if ($search.hasClass('selected'))
                        $search.removeClass('selected');
                } else if (event.which == 13) { // enter
                    window.location = $(this).data('action') + "?q=" + encodeURIComponent($(this).val());
                }
            });

            $search.find('.button').on('click', function (event) {
                event.preventDefault();

                //$search.find('input').trigger('focus');

                if ($search.hasClass('selected')) {
                    $search.removeClass('selected');
                } else {
                    $search.addClass('selected');
                    $search.find('input').focus();
                }
            });

            $(document).on('click', function (event) {
                if (!$(event.target).closest(".title-bar-search").length) {
                    $search.removeClass('selected');
                }
            });
        },

        searchBody: function () {
            var $search = $('.body-search');
            $search.find('input[type="text"]').on('keydown', function (event) {
                if (event.which == 13) { // enter
                    window.location = $(this).data('action') + "?q=" + encodeURIComponent($(this).val());
                }
            });
            $search.find('input[type="submit"]').on('click', function (event) {
                var val = $search.find('input[type="text"]').val();
                window.location = $(this).data('action') + "?q=" + encodeURIComponent(val);
            });
        },

        sliderMain: function () {
            var $slider = $('.js-slider-main-slides'),
                $slider_main = $('.js-slider-main'),
                structure_pagination = '<div class="slider-main-pagination js-slider-main-pagination"><div class="slider-main-pagination-wrapper page-center"><div class="slider-main-pagination-slides"></div><span class="slider-arrow slider-arrow-1 slider-arrow-prev js-slider-main-arrow-prev"></span><span class="slider-arrow slider-arrow-1 slider-arrow-next js-slider-main-arrow-next"></span></div></div>',
                $slides_pagination,
                $button_prev,
                $button_next,
                data_pagination,
                data_slider;

            // Add structure for pagination
            $slider_main.append(structure_pagination);

            $slides_pagination = $slider_main.find('.slider-main-pagination-slides');
            $button_prev = $('.js-slider-main-arrow-prev');
            $button_next = $('.js-slider-main-arrow-next');

            // Build slider pagination
            $slider.find('.slider-main-item').each(function () {
                var $this = $(this),
                    $thumb = $this.attr('data-thumb'),
                    $title = $this.attr('data-title'),
                    $category = $this.attr('data-category'),
                    structure_pagination_wrap = '<div class="slider-main-pagination-item"><figure class="js-image"><img src="' + $thumb + '" alt="." /></figure><div class="column"><p class="category js-category">' + $category + '</p><p class="js-content">' + $title + '</p></div><div>';

                $slides_pagination.append(structure_pagination_wrap);
            });

            $slider.owlCarousel({
                items: 1,
                singleItem: true,
                addClassActive: true,
                pagination: false,
                slideSpeed: 1000,
                transitionStyle: "goDown",
                afterMove: function () {
                    //console.log('aftermove');
                    $slider.find('.jwplayer').each(function () {
                        //console.log('stop');
                        jwplayer(this).stop();
                    });
                }
            });

            $slides_pagination.owlCarousel({
                items: 3,
                itemsDesktop: [1199, 3],
                itemsDesktopSmall: [979, 2],
                addClassActive: true,
                pagination: false,
                slideSpeed: 1000,
                responsive: true
            });

            data_pagination = $slides_pagination.data('owlCarousel');
            data_slider = $slider.data('owlCarousel');

            $slides_pagination.find('.owl-item').on('click', function (event) {
                event.preventDefault();

                var $index = $(this).index();

                data_slider.goTo($index);
            });

            $button_prev.on('mousedown', function (event) {
                event.preventDefault();
            });

            $button_next.on('mousedown', function (event) {
                event.preventDefault();
            });

            $button_prev.on('click', function (event) {
                event.preventDefault();
                data_pagination.prev();
            });

            $button_next.on('click', function (event) {
                event.preventDefault();
                data_pagination.next();
            });
        },

        slider: function () {
            var $slider = $('.js-slider-slides'),
                $slider_container = $('.js-slider-container'),
                structure_pagination = '<span class="slider-arrow slider-arrow-2 slider-arrow-prev js-slider-arrow-prev"></span><span class="slider-arrow slider-arrow-2 slider-arrow-next js-slider-arrow-next"></span>',
                $button_prev,
                $button_next,
                data_slider;

            // Add structure for pagination
            $slider_container.append(structure_pagination);

            $button_prev = $slider_container.find('.js-slider-arrow-prev');
            $button_next = $slider_container.find('.js-slider-arrow-next');

            $slider.owlCarousel({
                items: 1,
                singleItem: true,
                addClassActive: true,
                pagination: true,
                slideSpeed: 1000,
                paginationNumbers: true
            });

            data_slider = $slider.data('owlCarousel');

            $button_prev.on('mousedown', function (event) {
                event.preventDefault();
            });

            $button_next.on('mousedown', function (event) {
                event.preventDefault();
            });

            $button_prev.on('click', function (event) {
                event.preventDefault();
                data_slider.prev();
            });

            $button_next.on('click', function (event) {
                event.preventDefault();
                data_slider.next();
            });
        },

        sliderSolutions: function () {
            var $slider = $('.js-slider-solutions-slides'),
                $slider_container = $('.js-slider-solutions'),
                structure_pagination = '<div class="slider-arrows-container"><div class="slider-arrows page-center"><span class="slider-arrow slider-arrow-3 slider-arrow-prev js-slider-arrow-prev"></span><span class="slider-arrow slider-arrow-3 slider-arrow-next js-slider-arrow-next"></span></div></div>',
                $button_prev,
                $button_next,
                data_slider;

            // Add structure for pagination
            $slider_container.append(structure_pagination);

            $button_prev = $slider_container.find('.js-slider-arrow-prev');
            $button_next = $slider_container.find('.js-slider-arrow-next');

            $slider.owlCarousel({
                items: 1,
                singleItem: true,
                addClassActive: true,
                slideSpeed: 1000,
                pagination: true,
                paginationNumbers: true
            });

            data_slider = $slider.data('owlCarousel');

            $button_prev.on('mousedown', function (event) {
                event.preventDefault();
            });

            $button_next.on('mousedown', function (event) {
                event.preventDefault();
            });

            $button_prev.on('click', function (event) {
                event.preventDefault();
                data_slider.prev();
            });

            $button_next.on('click', function (event) {
                event.preventDefault();
                data_slider.next();
            });
        },

        carouselNews: function ($within) {
            var $sliders = $within ? $within.find('.js-carousel-news') : $('.js-carousel-news'),
                structure_pagination = '<span class="slider-arrow slider-arrow-2 slider-arrow-prev js-slider-arrow-prev"></span><span class="slider-arrow slider-arrow-2 slider-arrow-next js-slider-arrow-next"></span>';

            // Add structure for pagination
            //not here, depends on number of items!
            //$sliders.append(structure_pagination);

            $sliders.each(function () {
                var $this = $(this),
                    $slider = $this.find('.js-carousel-news-slides'),
                    $data_items = parseInt($this.attr('data-items'), 10) || 1,
                    $button_prev,
                    $button_next,
                    data_slider,
                    args = {
                        items: $data_items,
                        itemsDesktop: [1199, $data_items],
                        itemsDesktopSmall: [980, $data_items],
                        itemsTablet: [768, $data_items],
                        itemsTabletSmall: false,
                        itemsMobile: [479, $data_items],
                        addClassActive: true,
                        slideSpeed: 1000,
                        pagination: true,
                        paginationNumbers: true
                    };

                if ($data_items !== 1) {
                    args.singleItem = false;
                } else {
                    args.singleItem = true;
                }

                // do it here only if we have enough items
                if ($data_items <= $slider.find('.carousel-news-item').length) {
                    $this.append(structure_pagination);
                }

                $slider.owlCarousel(args);

                data_slider = $slider.data('owlCarousel');

                $button_prev = $this.find('.js-slider-arrow-prev');
                $button_next = $this.find('.js-slider-arrow-next');

                $button_prev.on('mousedown', function (event) {
                    event.preventDefault();
                });

                $button_next.on('mousedown', function (event) {
                    event.preventDefault();
                });

                $button_prev.on('click', function (event) {
                    event.preventDefault();
                    data_slider.prev();
                });

                $button_next.on('click', function (event) {
                    event.preventDefault();
                    data_slider.next();
                });
            });
        },

        carouselMedias: function ($within) {
            var $elts = $within ? $within.find('.js-carousel-medias') : $('.js-carousel-medias');
            $elts.each(function () {
                var $elt = $(this);
                var w = $elt.data('width');
                var args = {
                    items: 1,
                    itemsDesktop: [ /*1199*/w, 1],
                    itemsDesktopSmall: [980, 1],
                    itemsTablet: [768, 1],
                    itemsTabletSmall: false,
                    itemsMobile: [479, 1],
                    addClassActive: true,
                    slideSpeed: 1000 /*,
                    pagination: true,
                    paginationNumbers: true*/,
                    afterMove: function () {
                        //console.log('aftermove');
                        $elt.find('.jwplayer').each(function () {
                            //console.log('stop');
                            jwplayer(this).stop();
                        });
                    }
                };

                // do it here only if we have enough items
                var arrows = 1 <= $elt.find('.carousel-medias-item').length;
                if (arrows) {
                    $elt.append('<span class="slider-arrow slider-arrow-2 slider-arrow-prev js-slider-arrow-prev"></span>'
                        + '<span class="slider-arrow slider-arrow-2 slider-arrow-next js-slider-arrow-next"></span>');
                }

                var $items = $elt.find('.carousel-medias-items');
                $items.owlCarousel(args);

                if (arrows) {
                    var data_slider = $items.data('owlCarousel');

                    $elt.find('.js-slider-arrow-prev')
                        .on('mousedown', function (event) {
                            event.preventDefault();
                        })
                        .on('click', function (event) {
                            event.preventDefault();
                            data_slider.prev();
                        });

                    $elt.find('.js-slider-arrow-next')
                        .on('mousedown', function (event) {
                            event.preventDefault();
                        })
                        .on('click', function (event) {
                            event.preventDefault();
                            data_slider.next();
                        });
                }
            });
        },

        mapWorld: function () {
            var $points = $('.js-map-world-points li');

            $points.each(function () {
                var $this = $(this),
                    $data_id = $this.attr('data-id'),
                    $data_point_x = $this.attr('data-point-x'),
                    $data_point_y = $this.attr('data-point-y');

                $this.css({
                    'top': $data_point_y,
                    'left': $data_point_x
                });

                $this.on('click', function (event) {
                    event.preventDefault();

                    $('.map-world-region').fadeOut();
                    var $region_id = $('#' + $data_id);

                    $region_id.fadeIn(300);

                    var $owning_region = $(this).closest('.map-world-region');
                    if ($owning_region.length > 0) {
                        $region_id.find('.js-map-world-region-close').on('click', function () {
                            $region_id.fadeOut(300);
                            $(this).off();
                            var $submap = $('#' + $owning_region.attr('id'));
                            $submap.fadeIn(300);
                            $submap.find('.js-map-world-region-close').on('click', function () {
                                $submap.fadeOut(300);
                                $(this).off();
                            });
                        });
                    } else {
                        $region_id.find('.js-map-world-region-close').on('click', function () {
                            $region_id.fadeOut(300);
                            $(this).off();
                        });
                    }
                });
            });
        },

        timeline: function () {
            var $timeline = $('.js-timeline'),
                $timeline_slides = $('.js-timeline-slides'),
                data_timeline,
                pagination,
                data_pagination,
                pagination_elements = '',
                pagination_el,
                movePosition = 0;

            $timeline_slides.find('.js-timeline-slides-slide').each(function () {
                var $year = $(this).attr('data-year');
                pagination_elements += '<li>' + $year + '</li>';
            });

            $timeline.append('<ul class="timeline-pagination js-timeline-pagination">' + pagination_elements + '</ul>');

            pagination = $('.js-timeline-pagination');
            pagination_el = pagination.find('li');

            pagination.owlCarousel({
                items: 5,
                slideSpeed: 500,
                navigation: false,
                pagination: false,
                mouseDrag: false,
                touchDrag: false
            });

            data_pagination = pagination.data('owlCarousel');

            $timeline_slides.owlCarousel({
                items: 5,
                slideSpeed: 1000,
                addClassActive: true,
                navigation: false,
                pagination: false,
                mouseDrag: false,
                //touchDrag: false,
                scrollPerPage: true,
                afterMove: function () {
                    if ($(window).width() <= 979) {
                        var current = this.currentItem,
                            currentActive = $timeline_slides.find('.owl-item.active').length;

                        pagination.find('li').removeClass('selected');
                        pagination_el.eq(current).addClass('selected');
                        data_pagination.goTo(current);
                    }
                }
            });

            data_timeline = $timeline_slides.data('owlCarousel');

            $timeline.find('.owl-next').on('click', function (event) {
                event.preventDefault();
                var currentActive = $timeline_slides.find('.owl-item.active').length;

                if (!pagination.find('.owl-item:last li').is('.selected')) {
                    movePosition = movePosition + 1;
                    pagination.find('li').removeClass('selected');
                    pagination_el.eq(movePosition).addClass('selected');
                    if (movePosition % currentActive === 0) {
                        data_timeline.goTo(movePosition);
                        data_pagination.goTo(movePosition);
                    }
                }
            });

            $timeline.find('.owl-prev').on('click', function (event) {
                event.preventDefault();
                var currentActive = $timeline_slides.find('.owl-item.active').length;

                if (!pagination.find('.owl-item:first li').is('.selected')) {
                    movePosition = movePosition - 1;
                    pagination.find('li').removeClass('selected');
                    pagination_el.eq(movePosition).addClass('selected');
                    if (movePosition % currentActive === currentActive - 1) {
                        data_timeline.goTo(movePosition - (currentActive - 1));
                        data_pagination.goTo(movePosition - (currentActive - 1));
                    }
                }
            });

            pagination_el.eq(0).addClass('selected');
            pagination_el.on('click', function (event) {
                event.preventDefault();

                var $this = $(this),
                    slideIndex = $this;

                pagination.find('li').removeClass('selected');
                $this.addClass('selected');
                if (slideIndex > 4) {
                    data_timeline.goTo(slideIndex);
                }

                movePosition = $(this).parent('.owl-item').index();
            });
        },

        //buttonContact: function () {
        //    $('.js-button-contact').on('click', function(event) {
        //        event.preventDefault();
        //        /*global eurovia */
        //        eurovia.showBox($('#fly-box-contact'));
        //    });
        //},

        showBox: function ($id, arrow) {
            var $overlay = $('.fly-box-overlay'),
                structure_close = '<span class="fly-box-close" title="Close"></span>',
                show_arrow = arrow || false;

            $overlay.show();

            // Add arrow
            if (show_arrow) {
                $id.append(structure_close);
            }

            $id.show();

            $id.find('.fly-box-close').on('click', function () {
                //$id.hide();
                //$overlay.hide();
                eurovia.zapFlyBox();
                $(this).off();
            });
        },

        showBoxNews: function ($id, arrow) {
            var $overlay = $('.fly-box-news-overlay'),
                structure_close = '<span class="fly-box-close" title="Close"></span>',
                show_arrow = arrow || false;

            $overlay.show();

            // Add arrow
            if (show_arrow) {
                $id.find('.fly-box-container').append(structure_close);
            }

            $id.show();

            $id.find('.fly-box-close').on('click', function () {
                //$id.hide();
                //$overlay.hide();
                eurovia.zapFlyBox2();
                $(this).off();
            });
        },

        articlesInFlyBox: function ($within) {
            var $articles = $within ? $within.find('.articles-four') : $('.articles-four');
            if ($articles.length) {
                $articles.find('a').on('click', function (event) {
                    event.preventDefault();

                    var $href = $(this).attr('href');

                    /*global eurovia */
                    eurovia.showBoxNews($($href), true);
                });
            }
        },

        filterArchives: function () {
            $(".filter-articles-wrapper").mCustomScrollbar();
        },

        /**
         * Animate elements
         * @return void
         */
        animateElements: function () {
            var before = [],
                blocked = [],
                before_elements,
                runAnimate,
                init;

            if ($(window).width() > 979) {
                // Search all element to animations and I set to array all params
                $('.animate-load-elements').each(function (nr) {
                    var $this = $(this),
                        $type = $(this).attr('data-type').split(','), // All types to animation
                        $speed = $(this).attr('data-speed'), // Duration animate
                        $delay = $(this).attr('data-delay') || null, // Delay animate
                        name,
                        obj;

                    $.each($type, function (key, value) {
                        name = value;
                        obj = {};

                        obj[name] = $this.css(value);
                        before[nr] = {
                            el: $this,
                            speed: $speed,
                            delay: $delay,
                            position: $this.offset(),
                            params: obj
                        };

                        /*global Modernizr */
                        $this.css(Modernizr.prefixed('transition') + '-duration', '1ms');
                        $this.css(value, '0');

                        blocked[key] = true;
                    });
                });
            }

            before_elements = before.length;

            // Run animate
            runAnimate = function (para) {
                var el = para.el,
                    speed = para.speed,
                    delay = para.delay,
                    param = para.params;

                /*global Modernizr */
                el.css(Modernizr.prefixed('transition') + '-duration', speed);
                el.css(Modernizr.prefixed('transition') + '-delay', delay);

                $.each(param, function (key, value) {
                    el.css(key, value);
                });
            };

            init = function (ratio) {
                var $scroll_top = $(window).scrollTop(),
                    $window_height = $(window).height() - ($(window).height() * ratio),
                    i;

                //console.log('INIT');
                //console.log($scroll_top + ' ' + $window_height);
                for (i = 0; i < before_elements; i += 1) {
                    //console.log(i + ' ' + before[i].position.top);
                    if (($scroll_top > before[i].position.top - $window_height) && blocked[i] !== false) {
                        blocked[i] = false;
                        runAnimate(before[i]);
                    }
                }
            };

            if ($(window).width() > 979) {
                init(0);
                $(window).scroll(function () {
                    init(0.20);
                });
            }
        },

        selectFilter: function () {
            if ($(window).width() > 979) {
                $('.select-filter').on('mouseenter', function () {
                    $(this).addClass('active');
                });

                $('.select-filter').on('mouseleave', function () {
                    $(this).removeClass('active');
                });
            } else {
                $('.select-filter').on('click', function () {
                    if ($(this).hasClass('active')) {
                        $('.select-filter').removeClass('active');
                    } else {
                        $('.select-filter').removeClass('active');
                        $(this).addClass('active');
                    }
                });
            }
            /*
                        $(document).on('click', function(event) {
                            if (!$(event.target).closest('.select-filter-developed, .select-filter').length) {
                                alert('ok');
                                $('.select-filter').removeClass('active');
                            }
                        });
            */
        },

        /* eadj below */

        setFlyBoxDirection: function ($open) {
            var $set = $open.closest(".fly-box-set");
            var $all = $set.find('a.fly-box-href');
            $('#fly-box .fly-box-direction').off().hide();
            if ($set.length > 0 && $all.length > 1) {
                var $index = $all.index($open);
                if ($index > 0) {
                    $('#fly-box .fly-box-direction-prev').show().on('click', function (event) {
                        event.preventDefault();
                        eurovia.reOpenFlyBox($all.eq($index - 1));
                    });
                } else {
                    $('#fly-box .fly-box-direction-prev').hide();
                }
                if ($index < $all.length - 1) {
                    $('#fly-box .fly-box-direction-next').show().on('click', function (event) {
                        event.preventDefault();
                        eurovia.reOpenFlyBox($all.eq($index + 1));
                    });
                } else {
                    $('#fly-box .fly-box-direction-next').hide();
                }
            }
        },

        spinningFlyBoxWrapper: function ($box) {
            var $wrapper = $box.find('.fly-box-wrapper');

            if ($wrapper.length == 0) {
                $wrapper = $('<div class="fly-box-wrapper"></div>');
                $wrapper.prependTo($box.find('.fly-box-container'));
            }
            $wrapper.empty().html('<div class="spinner1">&nbsp;</div>');

            return $wrapper;
        },

        plugSocialNetworks: function ($within) {
            if (!$within) return; // addthis auto-plugs things
            if (typeof addthis === 'undefined') return;
            $within.find('.addthis_toolbox').each(function () {
                addthis.toolbox(this);
            });
        },

        loadFlyBox: function ($wrapper, $href, callback) {
            var $html = $('<div></div>');
            $html.load($href, function () {
                // if the wrapper is gone, no need to do anything
                if (!jQuery.contains(document.documentElement, $wrapper[0])) return;

                // load into wrapper
                // .children() does not work on text nodes, must use .contents()
                // detach to be safe and not create copies
                $wrapper.empty();
                $html.contents().detach().appendTo($wrapper);

                if (callback)
                    callback($wrapper);
            });
        },

        activateFlyBoxContent: function ($wrapper) {
            eurovia.plugSocialNetworks($wrapper);
            eurovia.carouselNews($wrapper);
            eurovia.carouselMedias($wrapper);
            //eurovia.articlesInFlyBox($wrapper);
            eurovia.plugFlyBox2Open($wrapper);
            eurovia.plugArticlesFilter($wrapper);
            eurovia.plugContactForm($wrapper);
        },

        activateFlyBox2Content: function ($wrapper) {
            eurovia.carouselNews($wrapper);
            eurovia.carouselMedias($wrapper);
        },

        reOpenFlyBox: function ($open) {
            var $wrapper = eurovia.spinningFlyBoxWrapper($('#fly-box'));
            eurovia.setFlyBoxDirection($open);
            eurovia.loadFlyBox($wrapper, $open.attr('href'), eurovia.activateFlyBoxContent);
        },

        plugFlyBoxOpen: function ($within) {
            var $opens = $within ? $within.find('a.fly-box-open') : $('a.fly-box-open');
            $opens.on('click', function (event) {
                event.preventDefault();

                var $wrapper = eurovia.spinningFlyBoxWrapper($('#fly-box'));
                eurovia.setFlyBoxDirection($(this));

                // open the box, don't add the arrow - we'll do it by ourselves
                var top = $(window).scrollTop() - 50;
                $('#fly-box').css('top', top);
                eurovia.showBox($('#fly-box'), false);

                eurovia.loadFlyBox($wrapper, $(this).attr('href'), eurovia.activateFlyBoxContent);
            });
        },

        plugFlyBox2Open: function ($within) {
            var $articles = $within ? $within.find('.articles-four') : $('.articles-four');
            $articles.find('a').on('click', function (event) {
                event.preventDefault();

                var $box = $('#fly-box-news');
                var $wrapper = eurovia.spinningFlyBoxWrapper($box);

                // fixme - plug? later...
                //eurovia.setFlyBoxDirection($(this));
                var top = $(window).scrollTop() - 100;
                $box.css('top', top);
                eurovia.showBoxNews($box, false);

                eurovia.loadFlyBox($wrapper, $(this).attr('href'));
            });
            var $opens = $within ? $within.find('a.fly-box2-open') : $('a.fly-box2-open');
            $opens.on('click', function (event) {
                event.preventDefault();

                var $box = $('#fly-box-news');
                var $wrapper = eurovia.spinningFlyBoxWrapper($box);

                $box.css('top', $(window).scrollTop() + 120);
                eurovia.showBoxNews($box, false);

                eurovia.loadFlyBox($wrapper, $(this).attr('href'), eurovia.activateFlyBox2Content);
            });
        },

        bangTo: function ($id) {
            var $target = $('#bang-' + $id);
            if ($target.length > 0) {
                var margin = $('body').hasClass('homepage') ? 110 : 140;
                var top = $target.offset().top - margin;
                if (top <= margin) top = 0;
                $('html, body').animate({
                    scrollTop: top
                }, 500);
            }
        },

        plugBangLinks: function () {
            $('a.abang').on('click', function (event) {
                var $href = $(this).attr('href');

                if ($href[0] != '#') {
                    var $wref = window.location.pathname + window.location.search;
                    var $pos = $href.indexOf('#');
                    // fixme - why would this not work anymore ?! ie returning now does nothing?!
                    if ($wref != $href.substring(0, $pos)) return;
                    $href = $href.substring($pos + 2);
                } else {
                    $href = $href.substring(2);
                }

                window.location.hash = '!' + $href;
                event.preventDefault();
                eurovia.bangTo($href);
            });
        },

        hashBang: function () {
            if (window.location.hash && window.location.hash[1] == '!') {
                eurovia.bangTo(window.location.hash.substring(2));
            }
        },

        plugZapBoxes: function () {
            if ($('#fly-box .fly-box-wrapper').length > 0) return; // not on immediate box

            $('#fly-box').on('click', function (event) {
                if ($(event.target).is('#fly-box'))
                    eurovia.zapFlyBox();
            });
            $('#fly-box-news').on('click', function (event) {
                if ($(event.target).is('#fly-box-news'))
                    eurovia.zapFlyBox2();
            });
            $('.fly-box-overlay').on('click', function () { eurovia.zapFlyBox(); });
            $('.fly-box-news-overlay').on('click', eurovia.zapFlyBox2);

            $(document).on('keydown', function (event) {
                if (event.which != 27) return;
                if ($('#fly-box-news').is(':visible')) eurovia.zapFlyBox2();
                else if ($('#fly-box').is(':visible')) eurovia.zapFlyBox();
            });
        },

        zapFlyBox: function () {
            $('.fly-box-overlay').hide();
            $('#fly-box').hide();
            $('#fly-box .fly-box-direction').off().hide();
            $('#fly-box .fly-box-wrapper').remove();
        },

        zapFlyBox2: function () {
            $('.fly-box-news-overlay').hide();
            $('#fly-box-news').hide();
            $('#fly-box-news .fly-box-direction').off().hide();
            $('#fly-box-news .fly-box-wrapper').remove();
        },

        immediateFlyBox: function () {
            var $chrefa = $('#fly-box .fly-box-wrapper a.fly-box-close-href');
            if ($chrefa.length > 0) {
                var $chref = $chrefa.attr('href');
                $chrefa.remove();
                $('#fly-box .fly-box-close').on('click', function () {
                    // should not be a banged url
                    $(this).off();
                    window.location = $chref;
                });
            } else {
                $('#fly-box .fly-box-close').on('click', function () {
                    eurovia.zapFlyBox();
                    $(this).off();
                });
            }
        },

        plugAjaxMoreLoad: function () {
            $('a.ajax-more-load').on('click', function (event) {
                event.preventDefault();

                var $load = $(this);

                // lock the link
                if ($load.data('loading')) return;
                $load.data('loading', true);

                var $container = $load.closest('.ajax-more').find('.ajax-more-ctnr');
                if ($container.length == 0) return;

                var $href = $load.attr('href');
                var $savedHtml = $load.html();
                $load.html('. . .');

                var $html = $('<div></div>');
                $html.load($href, function () {
                    // if the container is gone, no need to do anything
                    if (!jQuery.contains(document.documentElement, $container[0])) return;

                    // check for data
                    // found = update the link and remove data from html
                    // else, kill the link
                    var $data = $html.find('data');
                    if ($data.length > 0) {
                        var $nurl = $data.data('url');
                        $load.html($savedHtml).attr('href', $nurl);
                        $data.remove();
                    } else {
                        $load.hide();
                    }

                    // load into container
                    // .children() does not work on text nodes, must use .contents()
                    // detach to be safe and not create copies
                    $html.contents().each(function () {
                        $(this).detach().appendTo($container);
                        // activate here because $container is was empty
                        eurovia.plugFlyBoxOpen($(this));
                    });

                    // unlock the link
                    $load.removeData('loading');

                    var ellipsis = $(".ellipsis");
                    var nrLines = 3;
                    var ellHeight = parseInt(ellipsis.css('line-height'), 10) * nrLines;

                    ellipsis.dotdotdot({
                        height: ellHeight // this is the number of lines
                    });
                });
            });
        },

        setArticlesFilterTick: function ($tick, $selector) {
            var selectorTop = $selector.position().top;
            var selectorHeight = $selector.height();
            var tickTop = Math.floor(selectorTop + selectorHeight / 2 + 2);
            $tick.data('top', tickTop);
            tickTop += $selector.offsetParent().position().top;
            $tick.show().css('top', '' + tickTop + 'px');
        },

        whileScrolling: function ($wrapper, $tick) {
            var tickTop = $tick.data('top') + mcs.top;
            if (tickTop < 0 || tickTop > $wrapper.height()) {
                $tick.hide();
            } else {
                $tick.show().css('top', '' + tickTop + 'px');
            }
        },

        plugArticlesFilter: function ($within) {
            var $filtered = $within ? $within.find('div.js-filter-articles') : $('div.js-filter-articles');
            $filtered.each(function () {
                var $this = $(this);
                var $selectors = $this.find('.filter-articles-content a');

                var $wrapper = $this.find('.filter-articles-wrapper');
                $wrapper.after('<div class="filter-articles-tick"></div>');
                var $tick = $this.find('.filter-articles-tick');

                $wrapper
                    .mCustomScrollbar({ callbacks: { whileScrolling: function () { eurovia.whileScrolling($wrapper, $tick); } } });
                eurovia.setArticlesFilterTick($tick, $selectors.eq(0));

                $selectors.on('click', function (event) {
                    event.preventDefault();

                    var $selector = $(this);
                    eurovia.setArticlesFilterTick($tick, $selector);

                    // and then we want to load....
                    var $container = $this.find('.column-6');
                    $container.empty().html('<div class="spinner1">&nbsp;</div>');

                    var $html = $('<div></div>');
                    $html.load($selector.attr('href'), function () {
                        // if the container is gone, no need to do anything
                        if (!jQuery.contains(document.documentElement, $container[0])) return;

                        // load into container
                        // .children() does not work on text nodes, must use .contents()
                        // detach to be safe and not create copies
                        $container.empty();
                        $html.contents().detach().appendTo($container);

                        eurovia.carouselNews($container);
                        eurovia.carouselMedias($container);
                    });
                });
            });
        },

        plugContactForm: function ($within) {
            var $submit = $within ? $within.find('.contact-submit') : $('.contact-submit');
            $submit.on('click', function (event) {
                event.preventDefault();

                var $formContact = $('#form-contact');
                var $challengeFiled = $formContact.find("#recaptcha_challenge_field");
                var $responseFiled = $formContact.find("#recaptcha_response_field");

                var challenge = $challengeFiled.val();
                var response = $responseFiled.val();
                var json = '{ \
"type": "POST", \
"dataType": "jsonp", \
"data": { "challenge" : "' + challenge + '", "response" : "' + response + '", "test":"test"} \
}';
                $responseFiled.attr("data-parsley-remote-options", json);

                var $this = $(this);
                var $form = $this.closest('.contact-form');

                //require(["/scripts/parsley/parsley.js", "//ajax.googleapis.com/ajax/libs/jquery/1.10.1/jquery.min.js"],
                //function () {
                $formContact
                    .parsley()
                    .asyncValidate()
                    .done(function () {
                        //console.log("WELL DONE, VALID!" + $formContact + " JSON: " + json);
                        var $over = $form.find('.contact-form-over');
                        $over
                            .width($form.width())
                            .height($form.height())
                            .show();
                        var $feedback = $form.find('.contact-form-feedback');
                        $feedback
                            .width($form.width())
                            .height($this.outerHeight())
                            .html('<div class="spinner1" style="margin:0;position:absolute;right:' + ($this.width() / 2 + 12) + 'px;"></div>')
                            .show();
                        var cbya = '';
                        $form.find('#cbya input.checkbox:checked').each(function () {
                            if (cbya != '') cbya += ',';
                            cbya += $(this).attr('id').substr(5);
                        });
                        var cbyw = '';
                        $form.find('#cbyw input.checkbox:checked').each(function () {
                            if (cbyw != '') cbyw += ',';
                            cbyw += $(this).attr('id').substr(5);
                        });
                        var data = {
                            siteroot: $form.find('#frid').val(),
                            name: $form.find('#fname').val(),
                            location: $form.find('#flocation').val(),
                            phone: $form.find('#fphone').val(),
                            email: $form.find('#femail').val(),
                            ua: cbya,
                            uw: cbyw,
                            message: $form.find('#fmsg').val()
                        };
                        $.post('/Umbraco/Eurovia/Api/ContactUs', data, function (recv, status, request) {
                            $feedback.empty().html(recv);
                        });
                    })
                    .fail(function () {
                        //console.log("WELL DONE, FAILED!");
                        if (response) { Recaptcha.reload(); }
                    });
                //});
            });
        },

        plugCheckBoxAllnone: function ($within) {
            var $cbs = $within ? $within.find('.cb-allnone-ctnr') : $('.cb-allnone-ctnr');
            $cbs.each(function () {
                var $container = $(this);
                $container.find('input.checkbox').each(function () {
                    var $cb = $(this);
                    $cb.on('change', function () {
                        if ($cb.hasClass('cb-allnone')) {
                            var checked = this.checked;
                            var $ncb = $cb.parent().next().find('input.checkbox');
                            while ($ncb.length > 0 && !$ncb.hasClass('cb-allnone')) {
                                $ncb[0].checked = checked;
                                $ncb = $ncb.parent().next().find('input.checkbox');
                            }
                        }
                    });
                });
            });
        },

        loadFilteredContainer: function ($ajax, url) {
            var $filter = $ajax.find('.block-filter');
            var $over = $filter.find('.block-filter-overlay');
            var $load = $ajax.find('.ajax-more-load');
            var $ctnr = $ajax.find('.ajax-more-ctnr');

            $ctnr.empty().html('<div class="spinner1">&nbsp;</div>');
            $load.hide();
            $over.height($filter.height()).width($filter.width()).show();

            var $html = $('<div></div>');
            $html.load(url, function () {
                // if the container is gone, no need to do anything
                if (!jQuery.contains(document.documentElement, $ctnr[0])) return;

                // check for data
                // found = update the link and remove data from html
                // else, kill the link
                var $data = $html.find('data');
                if ($data.length > 0) {
                    var $nurl = $data.data('url');
                    $load.attr('href', $nurl).show();
                    $data.remove();
                } else {
                    $load.hide();
                }

                // load into wrapper
                // .children() does not work on text nodes, must use .contents()
                // detach to be safe and not create copies
                $ctnr.empty();
                $html.contents().detach().appendTo($ctnr);

                $over.hide();

                eurovia.plugFlyBoxOpen($ctnr);
            });
        },

        plugInfosNewsAndPublisFilters: function () {
            eurovia.plugInfosNewsOrPublisFilter($('.filter-infos-news'));
            eurovia.plugInfosNewsOrPublisFilter($('.filter-infos-publis'));
        },

        plugInfosNewsOrPublisFilter: function ($filters) {
            $filters.each(function () {
                var $filter = $(this);
                var url = $filter.data('url');
                var $dateFilter = $filter.find('.filter-infos-date');
                var $typeFilter = $filter.find('.filter-infos-type');
                var $ajax = $filter.closest('.ajax-more');

                $dateFilter.find('a').on('click', function (event) {
                    event.preventDefault();
                    var $a = $(this);
                    var label = $a.html();
                    $dateFilter.find('.select-filter-label p').html(label);
                    $dateFilter.data('date', $a.data('date'));
                    eurovia.loadFilteredContainer($ajax, getUrl(url, $dateFilter, $typeFilter));
                });

                $typeFilter.find('a').on('click', function (event) {
                    event.preventDefault();
                    eurovia.loadFilteredContainer($ajax, getUrl(url, $dateFilter, $typeFilter));
                });
            });

            function getUrl(url, $dateFilter, $typeFilter) {
                url += url.indexOf('?') > 0 ? '&' : '?';
                url += 'd=' + $dateFilter.data('date');
                var types = '';
                $typeFilter.find('input.checkbox').each(function () {
                    var $cb = $(this);
                    if (!$cb.hasClass('cb-allnone') && $cb[0].checked) types += $cb.val() + ',';
                });
                url += "&t=" + types;
                return url;
            }
        },

        plugInfosMediasFilter: function () {
            $('.filter-infos-medias').each(function () {
                var $filter = $(this);
                var url = $filter.data('url');
                var $ttFilter = $filter.find('.filter-infos-type');
                var $ajax = $filter.closest('.ajax-more');

                $ttFilter.find('a').on('click', function (event) {
                    event.preventDefault();
                    eurovia.loadFilteredContainer($ajax, getUrl(url, $ttFilter));
                });
            });

            function getUrl(url, $ttFilter) {
                url += url.indexOf('?') > 0 ? '&' : '?';
                var ty = '', th = '';
                var i = 0, yh = false;
                $ttFilter.find('input.checkbox').each(function () {
                    if (i++ == 0) return;
                    var $cb = $(this);
                    if ($cb.hasClass('cb-allnone')) {
                        yh = true;
                        return;
                    }
                    if ($cb[0].checked) {
                        if (yh)
                            th += $cb.val() + ',';
                        else
                            ty += $cb.val() + ',';
                    }
                });
                url += "ty=" + ty + '&th=' + th;
                return url;
            }
        },

        fixSelectFilterZIndex: function () {
            var i = 0;
            $('.select-filter').each(function () {
                var $filter = $(this);
                var zindex = $filter.css('z-index');
                $filter.css('z-index', zindex - i++);
            });
        },

        vplayer: function (id, video, options) {
            var jwopt = options;
            jwopt.file = video;
            if (!jwopt.width) jwopt.width = '100%';
            if (!jwopt.aspectratio) jwopt.aspectratio = '16:9';
            jwplayer(id).setup(jwopt);
        },

        hvplayer: function (id, video, image, options) {
            var wrap = $('#' + id).parent();

            eurovia.vplayer(id, video, {
                image: image,
                aspectratio: '940:452',
                stretching: 'fill',
                controls: 'false',
                volume: 80,
                events: {
                    onReady: function () {
                        wrap.find('.hv-start-box').show();
                    },
                    onPlay: function () {
                        wrap.find('.hv-control-play').removeClass('paused');
                        wrap.find('.hv-start-box').hide();
                        wrap.find('.hv-control-box').show();
                    },
                    onPause: function () {
                        wrap.find('.hv-control-play').addClass('paused');
                    },
                    onIdle: function () {
                        wrap.find('.hv-control-box').hide();
                        wrap.find('.hv-start-box').show();
                        wrap.find('.hv-start-button').html(options.ui.play);
                    },
                    onComplete: function () {
                        wrap.find('.hv-control-box').hide();
                        wrap.find('.hv-start-box').show();
                        wrap.find('.hv-start-button').html(options.ui.play);
                    }
                    //onMute: function(mute) {
                    //    wrap.find('.hv-control-sound').html(mute.mute ? 'SOUND' : 'MUTE');
                    //}
                }
            });

            var startButton = wrap.find('.hv-start-button');
            startButton.on('click', function () {
                $(this).html(options.ui.loading);
                var player = jwplayer(wrap.find('.jwplayer')[0]);
                player.play(true);
            });
            var controlPlay = wrap.find('.hv-control-play');
            controlPlay.on('click', function () {
                var player = jwplayer(wrap.find('.jwplayer')[0]);
                player.play();
            });
            var controlSound = wrap.find('.hv-control-sound');
            controlSound.on('click', function () {
                var player = jwplayer(wrap.find('.jwplayer')[0]);
                var cvol = player.getVolume();
                //if (cvol > 0) vol = cvol;
                player.setVolume(cvol > 0 ? 0 : 80);
                if (cvol == 0)
                    wrap.find('.hv-control-sound').removeClass('muted');
                else
                    wrap.find('.hv-control-sound').addClass('muted');
                //var mute = player.getMute();
                //player.setMute(!mute);
            });
        }
    };

    $(document).on('ready', function () {
        $.ajaxSetup({
            // Disable caching of AJAX responses
            cache: false
        });

        /*global eurovia */
        eurovia.init();

        window.readyActions = window.readyActions || [];
        window.ready = function (action) { action(); }
        for (var i = 0; i < window.readyActions.length; i++)
            window.readyActions[i]();
    });
}(jQuery, window, document));